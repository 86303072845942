import React from 'react';
import ReactDOM from 'react-dom';


const Motto = () => (
    <div id='motto'>
        <h1 className='im-fell-english-sc-regular'>Believe Brings Change</h1>
    </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Motto/>);